/**
 * Create element
 *
 * @param {string} type
 * @returns HTMLElement
 */
export const createElement = (type) => document.createElement(type);

/**
 * Query class | id | ...
 *
 * @param {string} selector
 * @returns HTMLElement
 */
export const select = (selector) => document.querySelector(selector);

/**
 * QuerySelectorAll class | id | ...
 *
 * @param {string} selector
 * @returns HTMLElement
 */
export const selectAll = (selector) => document.querySelectorAll(selector);

/**
 * Get body element
 */
export const body = select("body");

/**
 * Convert first letter to uppercase
 *
 * @param {Stirng} string
 * @returns HTMLElement
 */
export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Clear stirng for i18n replace all spaces
 * as well as other characters not allowed
 *
 * @param {string} string
 * @returns
 */
export const clearei18n = (string) => {
  return string
    .trim()
    .replace(/[^a-zA-Z ]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

/**
 * Convert JSON to Array
 *
 * @param {object} json - json object
 * @returns Array
 */
export const convertJSONToArray = (json) => {
  // Object.values(players);
  const array = [];
  for (let key in json) {
    array.push(json[key]);
  }
  return array;
};

/**
 * Check is object empty
 *
 * @param {object} obj
 * @returns
 */
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Set property to root html element
 *
 * @param {HTMLElement} root
 * @param {string} name
 * @param {string} height
 * @returns
 */
export const setPropertyHeight = (root, name, height) => {
  return root.style.setProperty(name, height);
};

/**
 * Get item from localStorage
 *
 * @param {string} nameItem
 * @returns
 */
export const getItemLocalStorage = (nameItem) =>
  JSON.parse(localStorage.getItem(nameItem));

/**
 * Set item to localStorage
 *
 * @param {string} nameItem
 * @param {string} value
 * @returns
 */
export const setItemLocalStorage = (nameItem, value) =>
  localStorage.setItem(nameItem, JSON.stringify(value));

/**
 * Remove item from localStorage
 *
 * @param {string} nameItem
 * @returns
 */
export const removeLocalStorage = (nameItem) =>
  localStorage.removeItem(nameItem);

/**
 * Count row in tbody
 *
 * @param {HTMLElement} table
 * @returns number
 */
export const countRowInTable = (table) =>
  table.querySelectorAll("tbody > tr").length;

/**
 * Count object inside array
 */
export const countObjectIsideArray = (storage) => {
  return storage.reduce(
    (counter, { status }) => (status === "0" ? (counter += 1) : counter),
    0
  );
};

/**
 * Detect if device is mobile
 */
export const hasTouchScreen = () => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

/**
 * Check if header exist
 */
export const checkIfHeaderExist = () => {
  const checkHeader = select("#header");
  return checkHeader ? "76px" : 0;
};

/**
 *
 */
export const checkApID = () => {
  const getAppID = getItemLocalStorage("HQappID");
  if (!getAppID) {
    // console.log("getAppID", getAppID, HQ.Properties.appId);
    setItemLocalStorage("HQappID", HQ.Properties.appId);
  } else if (HQ.Properties.appId !== getAppID) {
    // console.log("appId is different", HQ.Properties.appId);
    localStorage.clear();
    setItemLocalStorage("HQappID", HQ.Properties.appId);
  }
};

/**
 * Compare to date and return true if timeNow is bigger than time
 *
 * @param {string} time
 * @returns boolean
 */
export const compareTwoTimes = (time) => {
  let curTime = new Date();
  curTime = parseInt(
    curTime.getHours() + "" + ("0" + curTime.getMinutes()).slice(-2)
  );

  return curTime > time.replace(":", "") ? true : false;
};

export const markFavoritePlayers = () => {
  // get players from localstorage
  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  if (!getLocalStorage) return;

  const favoritePlayerOnMap = selectAll(".favorite-player-on-map");

  // remove all favorite player class
  favoritePlayerOnMap?.forEach((player) => {
    player.classList.remove("favorite-player-on-map");
  });

  if (getLocalStorage?.length <= 0) return;

  // add to array all favorite player
  const allFavoritePlayers = [];
  getLocalStorage.forEach((obj) => {
    allFavoritePlayers.push(obj);
  });

  const getAllDataIdPlayers = selectAll("[data-id-players]");
  // console.log("getAllDataIdPlayers", getAllDataIdPlayers.length);

  // set favorite player class, default is red #ff0000
  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/71
  getAllDataIdPlayers.forEach((player, index) => {
    if (allFavoritePlayers.length <= 0) return;

    const arrayPlayers = player.dataset.idPlayers;

    const favoritePplayersArray = arrayPlayers.split(",").map(Number);

    allFavoritePlayers.forEach((fav) => {
      if (favoritePplayersArray.includes(fav)) {
        getAllDataIdPlayers[index].classList.add("favorite-player-on-map");
      }
    });
  });
};

/**
 * Get bounds from to points
 */
export const getBounds = () => {
  const southWest = new L.LatLng(...HQ.Properties.maxBounds.southWest);
  const northEast = new L.LatLng(...HQ.Properties.maxBounds.northEast);
  return new L.LatLngBounds(southWest, northEast);
};

/**
 * Return length of object
 * @returns {number}
 */
export const objectLength = (object) => Object.keys(object).length;

/**
 * Array equals
 *
 * @param {array} a
 * @param {array} b
 * @returns
 */
export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

/**
 * Check if var is undefined
 *
 * @param {string} varName
 * @returns
 */
export const checkIfVarExist = (varName) => {
  return typeof varName === "undefined" ? false : true;
};

/**
 * Create svg icon with color, class name and name icon
 *
 * @param {object} class name, color icon, name icon
 */
export const svgIcon = ({ className, color, name }) => {
  const iconClassName = className ? `class="${className}"` : "";
  const iconColor = color ? `fill="${color}"` : "";
  return `<svg ${iconClassName}${iconColor}><use href="#${name}"></use></svg>`;
};

/**
 * Create span row width class *
 *
 * @param {string} className
 * @param {string} numberScores
 * @returns
 */
export const scoreNumberHtmlElement = (className, numberScores) => {
  return `<span class="text-${className}">${numberScores}</span>`;
};

/**
 * Get path url for vapor
 *
 * @returns String
 */
export const getPathForVapor = (logo) => {
  let imageStarWith = logo.startsWith("https://") ? true : false;
  return imageStarWith ? logo : document.body.dataset.path + logo;
};

/**
 * Check if iphone | ipad | ipod
 *
 * @returns boolean
 */
export const ios = () =>
  /iP(hone|od|ad)/.test(navigator.userAgent) && !window.MSStream;

/**
 * Fix issue width calculate height popup center
 */
export const updatePopupHeightCalculate = () => {
  const leafletPopupPane = select(".leaflet-popup-pane");
  leafletPopupPane?.addEventListener(
    "load",
    (e) => {
      let tagName = e.target.tagName;
      let popup = map._popup;

      if (tagName === "IMG" && popup && !popup._updated) {
        popup._updated = true; // Set flag to prevent looping.
        popup.update();
        setTimeout(() => {
          let px = map.project(popup._latlng);
          px.y -= popup._container.clientHeight / 2.2;
          map.panTo(map.unproject(px));
          popup._updated = false;
        }, 200);
      }
    },
    true
  );
};

/**
 * Set cookie
 *
 * @param {String} name
 * @param {String} value
 * @param {Number} days
 */
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    // date.setTime(date.getTime() + 30 * 1000); // 30 sec expires
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

/**
 * Get cookie
 *
 * @param {String} name
 * @returns null
 */
export const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Erase cookie
 *
 * @param {String} name
 */
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

/**
 * Set new localstorage
 */
export const removeIdFromLocalStorage = (id, storageName) => {
  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");
  const newLocalStorage = getLocalStorage.filter((x) => x !== Number(id));
  setItemLocalStorage(storageName, newLocalStorage);
};

/**
 * Copy element to memory
 *
 * @param {string} data
 */
export const ClipboardWriteText = (data) => {
  const input = document.createElement("input");
  input.classList.add("temp-input");
  input.setAttribute("style", "display: none;");

  document.body.appendChild(input);
  input.value = data;

  if (navigator.clipboard) {
    const buffer = navigator.clipboard.writeText(input.value);

    buffer.then(
      () => {
        // console.log(input.value);
        input.value = "";
      },
      () => {
        /* Rejected - clipboard failed */
        // console.log(";aaaaaaaaaa");
      }
    );
  } else {
    console.log("Browser Not compatible");
  }
};

/**
 * Get color from courses array depense on curse_id
 *
 * @param {number} courseID
 */
export const GetColorFromCourse = (coursID) => {
  if (!HQ.Properties.isMultiCourse) return;

  const arrayObj = convertJSONToArray(HQ.Properties.courses);
  return arrayObj.find((element) => element.id === coursID).color;
};

/**
 * Run Ads events
 *
 * @param {Boolean} type
 */
export const CreateAdsSlotInLeaderBoard = (type) => {
  setTimeout(() => {
    const rowAds = document.querySelectorAll(".ads-row");

    rowAds.forEach((element) => {
      try {
        var tude = window.tude || { cmd: [] };

        const baseSlot = element.dataset.baseSlot;
        const slotId = element.id;

        console.log("slotId exist", slotId);
        // console.log("tude", tude);

        tude.cmd.push(function () {
          window.tude.refreshAdsViaDivMappings([
            {
              divId: slotId,
              baseDivId: `pb-slot-leader-${baseSlot}`,
            },
          ]);
        });
      } catch (error) {}
    });
  }, 500);
};

/**
 * Check if number is integer if not add
 * 1/2 [&#189] to number and return string
 *
 * @param {Number} points
 * @returns String
 */
export const checkIsNumberIsInteger = (points) => {
  return Number.isInteger(points) ? points : `${Math.floor(points)} &#189`;
};
